import React, {useContext, useEffect, useState} from "react"
import {navigate} from "gatsby"
import moment from "moment";
import _ from "lodash"
import {useForm} from "react-hook-form"
import Button from "../Button";
import {getAccountFull} from "../AccountSummary"
import AuthContext from "../Context/AuthContext";
import classNames from "classnames";
import {AppSync} from "../../libraries/appsync";

export default () => {
  const [loading, setLoading] = useState(true)
  const [account, setAccount] = useState(null)
  const [apiSuccess, setApiSuccess] = useState(null)
  const [apiError, setApiError] = useState(null)
  const {register, errors, handleSubmit} = useForm();
  const {session, personalCode} = useContext(AuthContext)

  const formEdit = data => {
    const zipFormatted = `${data.zip.substring(0, 3)}-${data.zip.substring(3)}`

    setLoading(true)
    AppSync.post("/", {
      query: `
        mutation updateTapirsUser($personalCode: ID!, $input: UpdateTapirsUserInput!) {
          updateTapirsUser(personalCode: $personalCode, input: $input) {
            personal_c
          }
        }
      `,
      variables: {
        personalCode: `${personalCode}`,
        input: {
          name1: `${data.name1}`,
          name2: `${data.name2}`,
          name1_kkn: `${data.name1_kkn}`,
          name2_kkn: `${data.name2_kkn}`,
          birth_ymd: account.birth_ymd,
          gender: `${data.gender}`,
          country: account.country,
          zip: `${zipFormatted}`,
          prefecture: `${data.prefecture}`,
          address1: `${data.address1}`,
          address2: `${data.address2}`,
          tel1: `${data.tel1}`,
        }
      }
    }).then(res => {
      if (_.has(res.data, "errors")) {
        setApiError(<span>ご注文者情報を更新できませんでした。</span>)
        setLoading(false)
        return navigate("/account/edit/#update=error")
      }

      setApiSuccess(<span>ご注文者情報を更新しました。</span>)
      setLoading(false)
      return navigate("/account/edit/#update=success")
    })
  }

  useEffect(() => {
    if (_.isEmpty(session)) {
      setApiError(<span>ご注文者情報はありません。</span>)
      return
    }

    setApiError(null)
    setLoading(true)

    getAccountFull(personalCode)
    .then(res => {
      setLoading(false)
      setAccount(_.get(res.data, "data.getTapirsUserFull"))
    })
    .catch(err => {
      console.error(err)
      setLoading(false)
      setApiError(err)
    })
  }, [personalCode])

  const formErrorClass = validateResult => validateResult ? "error" : ""

  return (
    <div className={classNames({loading: loading}, "container", "bg-white", "inner")}>
      {(loading === false && apiSuccess) && <p className="update success">{apiSuccess}</p>}
      {(loading === false && apiError) && <p className="update error">エラー：{apiError}</p>}

      <h2>ご注文者情報の変更</h2>

      <p>入力項目は全て必須です。</p>

      {account && (
        <form onSubmit={handleSubmit(formEdit)}>
          <div className="col2">
            <div className="field">
              <label htmlFor="name1">氏名（姓）</label>
              <input type="text" name="name1" id="name1" className={formErrorClass(errors.name1)} defaultValue={account.name1} ref={register({required: true})} />
              {/*<p className="comment">※補足が必要な場合、ここに入ります。</p>*/}
              {errors.name1 && <p className="comment error">入力してください</p>}
            </div>
            <div className="field">
              <label htmlFor="name2">氏名（名）</label>
              <input type="text" name="name2" id="name2" className={formErrorClass(errors.name2)} defaultValue={account.name2} ref={register({required: true})} />
              {/*<p className="comment">※補足が必要な場合、ここに入ります。</p>*/}
              {errors.name2 && <p className="comment error">入力してください</p>}
            </div>
          </div>
          <div className="col2">
            <div className="field">
              <label htmlFor="name1_kkn">氏名カナ（姓）</label>
              <input type="text" name="name1_kkn" id="name1_kkn" className={formErrorClass(errors.name1_kkn)} defaultValue={account.name1_kkn} ref={register({required: true, pattern: /^[ァ-ヶー　]+$/})} />
              {errors.name1_kkn && <p className="comment error">全角カタカナで入力してください</p>}
            </div>
            <div className="field">
              <label htmlFor="name2_kkn">氏名カナ（名）</label>
              <input type="text" name="name2_kkn" id="name2_kkn" className={formErrorClass(errors.name2_kkn)} defaultValue={account.name2_kkn} ref={register({required: true, pattern: /^[ァ-ヶー　]+$/})} />
              {errors.name2_kkn && <p className="comment error">全角カタカナで入力してください</p>}
            </div>
          </div>
          <div>
            <div className="field">
              <span className="form-label">生年月日</span>
              <p>{moment(account.birth_ymd).format("YYYY年MM月DD日")}</p>
            </div>
          </div>
          <div>
            <div className="field">
              <span className="form-label">性別</span>
              <p>
                <input type="radio" name="gender" value="1" id="gender-1" defaultChecked={account.gender === 1} ref={register({ required: true })} /> <label htmlFor="gender-1">男性</label>
                <span style={{paddingLeft: "2em"}}/>
                <input type="radio" name="gender" value="2" id="gender-2" defaultChecked={account.gender === 2} ref={register({ required: true })} /> <label htmlFor="gender-2">女性</label>
              </p>
              {errors.gender && <p className="comment error">選択してください</p>}
            </div>
          </div>
          <div>
            <div className="field">
              <label htmlFor="zip">郵便番号</label>
              <input type="text" name="zip" id="zip" maxLength="7" className={formErrorClass(errors.zip)} defaultValue={account.zip && account.zip.replace("-", "")} style={{width: "10em"}} placeholder="1600000" ref={register({required: true, pattern: /^\d{7}$/})} />
              <p className="comment">※半角数字のみで入力</p>
              {errors.zip && <p className="comment error">半角数字7文字で入力してください</p>}
            </div>
          </div>
          <div>
            <div className="field">
              <label htmlFor="prefecture">都道府県</label>
              <select name="prefecture" id="prefecture" className={formErrorClass(errors.prefecture)} defaultValue={account.prefecture} style={{width: "10em"}} ref={register({required: true})}>
                <option value="">▼都道府県</option>
                {_.map(["北海道","青森県","岩手県","宮城県","秋田県","山形県","福島県",
                  "茨城県","栃木県","群馬県","埼玉県","千葉県","東京都","神奈川県",
                  "新潟県","富山県","石川県","福井県","山梨県","長野県","岐阜県",
                  "静岡県","愛知県","三重県","滋賀県","京都府","大阪府","兵庫県",
                  "奈良県","和歌山県","鳥取県","島根県","岡山県","広島県","山口県",
                  "徳島県","香川県","愛媛県","高知県","福岡県","佐賀県","長崎県",
                  "熊本県","大分県","宮崎県","鹿児島県","沖縄県"], item => (
                  <option value={item} key={item}>{item}</option>
                ))}
              </select>
              {errors.prefecture && <p className="comment error">選択してください</p>}
            </div>
          </div>
          <div>
            <div className="field">
              <label htmlFor="address1">住所1 市区町村番地</label>
              <input type="text" name="address1" id="address1" className={formErrorClass(errors.address1)} defaultValue={account.address1} ref={register({required: true})} />
              {errors.address1 && <p className="comment error">入力してください</p>}
            </div>
          </div>
          <div>
            <div className="field">
              <label htmlFor="address2">住所2 建物名</label>
              <input type="text" name="address2" id="address2" className={formErrorClass(errors.address2)} defaultValue={account.address2} ref={register} />
              <p className="comment">※部屋番号までご登録ください</p>
            </div>
          </div>
          <div>
            <div className="field">
              <label htmlFor="tel">電話番号1</label>
              <input type="tel" name="tel1" id="tel" className={formErrorClass(errors.tel1)} defaultValue={account.tel1} placeholder="09000000000" ref={register({required: true, pattern: /^\d{10,}$/})} />
              <p className="comment">※半角数字のみで入力</p>
              {errors.tel1 && <p className="comment error">半角数字のみで入力してください</p>}
            </div>
          </div>

          <div className="submit" style={{margin: "50px auto 30px"}}>
            <Button type={"submit"}>ご注文者情報を変更する</Button>
          </div>
        </form>
      )}
    </div>
  )
}