import React, {useContext, useEffect} from "react"
import {navigate} from "gatsby";
import AuthContext from "../../components/Context/AuthContext";
import Layout from "../../components/Layout"
import SEO from "../../components/SEO"
import AccountEdit from "../../components/AccountEdit"
import {ButtonAccountTop} from "../../components/Button";
import PageNavigation from "../../components/PageNavigation";
import PageTitle from "../../components/PageTitle";

export default () => {
  const {session} = useContext(AuthContext)

  useEffect(() => {
    if (session === undefined) {
      navigate("/login/")
    }
  }, [session])

  return (
    <Layout>
      <SEO title="ご注文者情報の変更"/>
      <section className="inner">
        <PageTitle>アカウント管理</PageTitle>
        <AccountEdit/>
        <PageNavigation>
          <ButtonAccountTop/>
        </PageNavigation>
      </section>
    </Layout>
  )
}